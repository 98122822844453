<template>
    <section>

        <AppBodyHeader />

        <b-container>
            <b-row>
                <b-col>

                    <Box>

                        <template #toolbox>

                            <ToolBoxButton label="Atualizar Dados" icon="arrow-clockwise" @click="loadData" :loading="loading" />
                            <ToolBoxButton label="Novo Usuário" icon="plus" to="/user/new" />

                        </template>
                        
                        <template #nopadding>
                        
                            <Grid :model="model" :data="data" :loading="loading" :error="error" orderBy="userId">

                                <template #action="slotProps">

                                    <a @click="edit(slotProps.row)" title="Editar usuário" href="javascript:void(0);">
                                        <b-icon icon="pencil-square" />
                                    </a>

                                    <a @click="passwordReset(slotProps.row)" title="Resetar senha do usuário" href="javascript:void(0);">
                                        <b-icon icon="key" />
                                    </a>

                                    <a @click="remove(slotProps.row)" title="Remover usuário" href="javascript:void(0);">
                                        <b-icon icon="trash" />
                                    </a>

                                </template>

                            </Grid>

                        </template>

                    </Box>

                </b-col>
            </b-row>
        </b-container>

    </section>
</template>

<script>

import AppBodyHeader from '@/components/AppBody/AppBodyHeader'
import Box from '@/components/Box/Box'
import ToolBoxButton from '@/components/Box/ToolBox/ToolBoxButton'
import Grid from '@/components/Grid/Grid'

export default {

    name: 'UserGrid',
    
    components: {
        AppBodyHeader,
        Box,
        ToolBoxButton,
        Grid,
    },

    data() {
        return {

            loading: true,
            error: false,
            runningNewDb: false,
            
            model: [
                { label: 'ID', name: 'userId' },
                { label: 'Nome', name: 'userName' },
                { label: 'Login', name: 'login' },
                { label: 'Perfil', name: 'profileName' },
                { label: 'Gerado em', name: 'insertedIn' }
            ],

            data: []

        }
    },

    mounted() {

        this.loadData();

    },

    methods: {

        edit(user) {

            this.$router.push(`/user/${user.userId}`);

        },

        remove(user) {

            if(confirm(`Deseja realmente remover o usuário "${user.userName} <${user.login}>"?`)) {

                this.loading = true;

                this.$http.delete(`${this.$store.getters.api}/user/${user.userId}`)
                    .then(() => {

                        this.loadData();

                    })
                    .catch(() => {
                        alert('Falha ao realizar a exclusão!');
                        this.loading = false;
                    });

            }

        },

        passwordReset(user) {

            if(confirm(`Deseja realmente resetar a senha do usuário "${user.userName} <${user.login}>"?`)) {

                this.loading = true;

                this.$http.post(`${this.$store.getters.api}/user/${user.userId}/password-reset`)
                    .then((response) => {

                        alert(`Nova senha do usuário: ${response.data.data}`);

                        this.loadData();

                    })
                    .catch(() => {
                        alert('Falha ao realizar o reset!');
                        this.loading = false;
                    });

            }

        },

        loadData() {

            this.loading = true;
            this.error = false;

            this.$http.get(`${this.$store.getters.api}/user`)
                .then(response => {

                    if(!response.data) {

                        alert('Nenhum dado carregado');
                        return;

                    }

                    this.data = response.data.data;

                    if(this.data.length) {

                        this.data = this.data.map(user => {

                            return this.dataTransform(user);

                        });

                    }
                    
                })
                .catch(() => {
                    this.error = true;
                })
                .finally(() => {
                    this.loading = false;
                });

        },

        dataTransform(user) {

            return {
                userId: user.userId,
                profileName: user.profile.profileName,
                userName: user.userName,
                login: user.login,
                insertedIn: this.$moment(user.insertedIn).format("DD/MM/YYYY HH:mm:ss")
            }

        },

    },

}

</script>