<template>
    <div :class="$style.container">

        <div :class="$style.tableContainer">
            <table :class="$style.table">
                <thead>
                    <tr>
                        <td
                            v-for="column in $props.model"
                            :key="column.name"
                            :class="$style.dataColumn"
                            @click="setOrderBy(column)"
                        >
                            <b-icon v-if="internalOrderBy == column.name" :icon="internalSortAsc ? 'caret-down' : 'caret-up'" :class="$style.sortIcon" />
                            {{ column.label }}
                        </td>
                        <td v-if="hasAction" :class="$style.actionsColumn">Ações</td>
                    </tr>
                </thead>
                <tbody v-if="!error" :class="$style.data">
                    <tr v-for="(row, index) in dataResult" :key="index">
                        <td v-for="column in $props.model" :key="column.name">
                            <div v-if="row[column.name]">
                                
                                <CellLongText v-if="column.component == 'LongText'">{{ row[column.name] }}</CellLongText>
                                
                                <StatusLabel v-else-if="column.component == 'StatusLabel'" :status="row[column.name]" />
                                
                                <a v-else-if="column.component == 'Link'" :href="row[column.name]" target="_blank">{{ row[column.name] }}</a>
                                
                                <span v-else>{{ row[column.name] }}</span>
                            
                            </div>
                        </td>
                        <td v-if="hasAction" :class="$style.actionsColumn">
                            
                            <a v-for="(action, index) in $props.actions" :key="index" v-on:click="actionClick(action, row)" :title="action.title" href="javascript:void(0);">
                                <b-icon v-if="action.icon" :icon="action.icon"></b-icon>
                                <span v-if="action.label">{{ action.label }}</span>
                            </a>

                            <slot name="action" v-bind:row="row"></slot>

                        </td>
                    </tr>
                </tbody>
                <tbody v-if="error">
                    <tr>
                        <td :colspan="$props.model.length + 1">
                            Falha ao carregar os dados...
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div v-if="loading" :class="$style.loader">
            <Loader />
        </div>

    </div>
</template>

<script>

import Loader from '../Loader'
import CellLongText from './CellLongText'
import StatusLabel from '@/components/StatusLabel/StatusLabel'

export default {

    name: 'Grid',

    components: {
        Loader,
        CellLongText,
        StatusLabel
    },

    props: {

        model: {
            default: null
        },
        
        data: {
            default: null
        },

        actions: {
            default: null
        },

        orderBy: {
            default: null
        },

        sortAsc: {
            type: Boolean,
            default: true
        },

        loading: {
            type: Boolean,
            default: false
        },

        error: {
            type: Boolean,
            default: false
        }

    },

    data() {
        return {

            internalOrderBy: this.$props.orderBy,
            internalSortAsc: this.$props.sortAsc

        }
    },

    computed: {

        dataResult() {

            let data = this.$props.data;

            if(this.internalOrderBy != null) {
                data = data.sort((a, b) => {
                    return a[this.internalOrderBy] == b[this.internalOrderBy]
                        ? 0
                        : a[this.internalOrderBy] > b[this.internalOrderBy]
                            ? this.internalSortAsc ? 1 : -1
                            : this.internalSortAsc ? -1 : 1;
                });
            }
            
            return data;

        },

        hasAction() {

            return (this.$props.actions && this.$props.actions.length > 0)
                || this.$scopedSlots.action;

        }

    },

    methods: {

        actionClick(action, row) {

            this.$emit('actionClick', action, row);

        },

        setOrderBy(column) {

            this.internalSortAsc = this.internalOrderBy != column.name ? true: !this.internalSortAsc;
            this.internalOrderBy = column.name;

        },

    }

}

</script>

<style lang="scss" module>

.container {

    max-height: 70vh;
    position: relative;

}

.tableContainer {

    max-height: 70vh;
    min-height: 100px;
    overflow: auto;

}

.table {

    border-bottom: 3px solid var(--theme-color-1);
    width: 100%;

    th,
    td {

        padding: .8rem .5rem;

    }

    th:first-child,
    td:first-child {

        padding-left: 2rem;

    }

    th:last-child,
    td:last-child {

        padding-right: 2rem;

    }

    thead {

        td,
        th {

            background: #fff;
            border-bottom: 3px solid var(--theme-color-1);
            font-size: .8rem;
            font-weight: 700;
            padding-bottom: .6rem;
            padding-top: 1.4rem;
            position: sticky;
            text-transform: uppercase;
            top: 0;
            vertical-align: bottom;
            z-index: 100;

            &:after {
                
                background-color: var(--theme-color-1);
                bottom: -2px;
                content: '';
                height: 2px;
                left: 0;
                position: absolute;
                width: 100%;

            }

            &.dataColumn {

                cursor: pointer;

            }

            .sortIcon {

                float: left;
                margin: 2px 0 0 -14px;

            }

        }

    }


}

.data {

    td,
    th {

        transition: .1s all linear;

    }
    

    tr:nth-child(even) {

        td,
        th {

            background-color: #f5f5f5;

        }

    }

    tr:hover {

        td,
        th {

            background-color: var(--theme-color-2);
            color: #000;

        }

    }

}

.actionsColumn {

    text-align: center;

    a {

        margin: 0 5px;

    }

}

.loader {

    align-items: center;
    background-color: rgba(255, 255, 255, .7);
    cursor: wait;
    display: flex;
    justify-content: center;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 101;

}

</style>