<template>
    <button
        :class="[
            $style.button,
            fluid === true || fluid === '' ? $style.fluid : '',
            loading ? $style.loading : ''
        ]"
        :type="$props.type"
        v-on:click="click()"
        :title="title"
    >
        <span>

            <b-icon v-if="$props.icon" :icon="$props.icon" :class="[$style.icon, !$props.label ? $style.iconNoLabel : '']"></b-icon>

            {{ $props.label }}

            <slot></slot>

            <Loader v-if="loading" :class="$style.loader" text="" color="white" size="small" />

        </span>
    </button>
</template>

<script>

import Loader from '@/components/Loader.vue'

export default {

    name: 'ToolBoxButton',

    components: {
        Loader
    },

    props: {

        type: {
            default: 'button'
        },

        label: {
            default: null
        },

        icon: {
            default: null
        },

        tooltip: {
            default: null
        },

        fluid: {
            default: false
        },

        loading: {
            default: false
        },

        to: {
            default: null
        }

    },

    computed: {

        title() {

            return this.$props.tooltip || this.$props.label;

        }

    },

    methods: {

        click() {

            if(!this.loading) {
                
                if (this.$props.to) {

                    this.$router.push(this.$props.to);
                    return;

                }
                
                this.$emit('click');

            }

        }

    }

}

</script>

<style lang="scss" module>

.button {

    background-color: #fff;
    border: 0 none;
    border-left: 1px solid var(--separator-color);
    border-radius: 0;
    color: var(--font-color);
    height: 40px;
    margin: 0;
    padding: 10px 15px;
    position: relative;

    &:hover {
        
        background-color: var(--theme-color-2);
        color: #000;

    }

}

.icon {
    
    margin-right: .5rem;

    &NoLabel {

        margin-right: 0;

    }

}

.fluid {

    margin: 0;
    width: 100%;

    .icon {

        margin-left: -1rem;

    }

}

.loading {

    color: transparent;
    cursor: wait;
    pointer-events: none;

    .loader {

        left : 50%;
        position: absolute;
        transform: translateX(-50%) translateY(-50%);
        top: 50%;

    }

}

</style>