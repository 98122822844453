<template>
    <span>
        
        <b-icon v-if="$props.status == statusEnum.ready" :class="[$style.icon, $style.iconReady]" icon="stop-circle" />
        <b-icon v-if="$props.status == statusEnum.running" :class="[$style.icon, $style.iconRunning]" icon="clock" />
        <b-icon v-if="$props.status == statusEnum.success" :class="[$style.icon, $style.iconSuccess]" icon="check-circle" />
        <b-icon v-if="$props.status == statusEnum.fail" :class="[$style.icon, $style.iconFail]" icon="exclamation-triangle" />
        
        <span v-if="$props.status" :class="$style.status">{{ $props.status }}</span>
        
        <span v-if="typeof $props.count == 'number'" :class="$style.count">({{ $props.count }})</span>

    </span>
</template>

<script>

import statusEnum from './statusEnum'

export default {

    name: 'StatusLabel',

    props: {

        status: {
            default: null
        },

        count: {
            default: null
        }

    },

    data() {
        return {

            statusEnum: statusEnum

        }
    }

}

</script>

<style lang="scss" module>

.icon {

    margin-right: .3rem;

    &Ready {
        color: var(--theme-color-1);
    }

    &Running {
        color: #1e91e2;
    }

    &Success {
        color: #24b10a;
    }

    &Fail {
        color: #e82f2f;
    }


}

.count {

    margin-left: .3em;

}

</style>