<template>
    <section :class="$style.box">

        <header v-if="$props.title" :class="[$style.header, $props.collapsible ? $style.headerCollapsible : '']" :v-if="$props.title" @click="collapseToggle">

            <!-- Title -->
            <b-icon :class="$style.icon" :icon="$props.icon"></b-icon>
            <h2 :class="$style.title">{{ $props.title }}</h2>

            <slot name="header" />

            <!-- Status -->
            <status-label v-if="$props.status" :class="$style.statusLabel" :status="$props.status" />

            <!-- Collapse Icon -->
            <b-icon v-if="$props.collapsible" :icon="isCollapsed ? 'chevron-up' : 'chevron-down'" />

        </header>

        <ToolBox v-if="$scopedSlots.toolbox && !isCollapsed">
            <slot name="toolbox" />
        </ToolBox>

        <div v-if="$slots.nopadding && (!isCollapsed || !$props.title)" :class="[$style.container, $style.containerNoPadding]">
            
            <slot name="nopadding" v-if="!loading" />

            <div v-else :class="$style.loader">
                <Loader />
            </div>

        </div>

        <div v-if="$slots.default && (!isCollapsed || !$props.title)" :class="$style.container">
            
            <slot v-if="!loading" />

            <div v-else :class="$style.loader">
                <Loader />
            </div>

        </div>

    </section>
</template>

<script>

import Loader from '@/components/Loader';
import StatusLabel from '@/components/StatusLabel/StatusLabel'
import ToolBox from '@/components/Box/ToolBox/ToolBox'

export default {

    name: 'Box',

    components: {
        Loader,
        StatusLabel,
        ToolBox
    },

    props: {

        title: {
            default: null
        },

        icon: {
            default: 'caret-right'
        },

        loading: {
            default: false
        },

        collapsible: {
            type: Boolean,
            default: false
        },

        collapsed: {
            type: Boolean,
            default: false
        },

        status: {
            default: null
        },

    },

    data() {
        return {

            isCollapsed: false,

        }
    },

    beforeMount() {

        this.isCollapsed = this.$props.collapsed;

    },

    methods: {

        collapseToggle() {

            if(this.$props.collapsible)
                this.isCollapsed = !this.isCollapsed;

        }

    }

}

</script>

<style lang="scss" module>

.box {

    box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, .1);
    margin-bottom: 2rem;

}

.header {

    align-items: center;
    background-color: #fff;
    border-bottom: 1px solid var(--separator-color);
    display: flex;
    padding: 2rem;

    .title {

        flex-grow: 1;
        font-family: var(--secundary-font);
        font-size: 1rem;
        font-weight: 700;
        margin: 0;
        text-transform: uppercase;

    }

    .icon {

        color: #ccc;
        margin: 0 1rem 0 -0.5rem;

    }

    .statusLabel {

        margin-right: 2rem;

    }

    &Collapsible {

        cursor: pointer;

        &:hover {

            box-shadow: 0 0 5px 1px var(--theme-color-2);
            // box-shadow: 0 0 0 3px rgba(#f7ec4d, .5);

        }

    }

}

.container {

    background-color: #fff;
    overflow: hidden;
    padding: 2rem 2rem 0 2rem;

    &NoPadding {

        padding: 0;

    }

}

.loader {

    padding: 2rem 0 3rem 0;

}

</style>