<template>
    <div :class="$style.cellLongText">
        
        <button :class="$style.toggleButton" @click="toggle">
            <b-icon :icon="opened ? 'dash' : 'plus'" />
        </button>
        
        <div :class="[$style.content, opened ? $style.openedContent : '']">
            <slot />
        </div>

    </div>
</template>

<script>

export default {

    name: 'CellLongText',

    data() {
        return {

            opened: false

        }
    },

    methods: {

        toggle() {

            this.opened = !this.opened;

        }

    }

}

</script>

<style lang="scss" module>

.cellLongText {

    min-height: 19px;
    position: relative;
    top: 0;

    .toggleButton {
        
        background: none;
        border: 0 none;
        cursor: pointer;
        left: -1rem;
        height: 19px;
        padding: 0;
        position: absolute;
        top: 0;
        width: 1rem;

    }

    .content {

        max-height: 19px;
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

    }

    .openedContent {
        
        white-space: pre;
        max-height: 50vh;
        max-width: 100%;
        overflow: auto;

    }

}


</style>