<template>
    <div :class="$style.toolBox">
        <slot />
    </div>
</template>

<script>

export default {

    name: 'ToolBox'

}

</script>

<style lang="scss" module>

.toolBox {

    background-color: #f7f7f7;
    border-bottom: 1px solid var(--separator-color);
    display: flex;
    height: 41px;
    justify-content: flex-end;

}

</style>